.tb-breadcrumbs {
  font-family: 'Proxima Nova';
  font-weight: 300;
  background: $bg-grey;
  align-items: center;
  margin: 16px;

  &__previous {
      font-size: 18px;
      line-height: 22px;
      letter-spacing: 0.1em;

      color: $color-red;
      text-transform: uppercase;
  }

  & .breadcrumb .breadcrumb-item.active {
      
  }

  &__current {
      font-size: 50px;
      line-height: 61px;
      color: $bg-dark;
      text-transform: capitalize;
  }
}
