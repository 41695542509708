.tb-baby-list {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 32px;
  align-items: center;
  justify-content: center;

  @include breakpoint-less(lg) {
    gap: 32px;
  }

  @include breakpoint-less(md) {
    grid-template-columns: 1fr;
  }

  &__item {
    position: relative;
    width: 100%;
    max-width: 550px;
    margin: 0 auto;
    aspect-ratio: 2 / 1;
    background-position: center;
    background-size: cover;
  }

  &__free {
    position: absolute;
    top: 5%;
    left: 5%;
    background-color: rgba(0, 0, 0, 0.4);
    color: $color-white;
    padding: 0px 5px;
  }

  &__menu {
    position: absolute;
    top: 5%;
    right: 5%;
    background-image: url("data:image/svg+xml,%3Csvg width='17' height='60' viewBox='0 0 17 60' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.30005 16.4003C12.6079 16.4003 16.1 12.908 16.1 8.60022C16.1 4.2924 12.6079 0.800293 8.30005 0.800293C3.99223 0.800293 0.5 4.2924 0.5 8.60022C0.5 12.908 3.99223 16.4003 8.30005 16.4003Z' fill='white'/%3E%3Cpath d='M8.30005 38.1C12.6079 38.1 16.1 34.6079 16.1 30.3C16.1 25.9922 12.6079 22.5 8.30005 22.5C3.99223 22.5 0.5 25.9922 0.5 30.3C0.5 34.6079 3.99223 38.1 8.30005 38.1Z' fill='white'/%3E%3Cpath d='M8.30005 59.9003C12.6079 59.9003 16.1 56.408 16.1 52.1002C16.1 47.7924 12.6079 44.3003 8.30005 44.3003C3.99223 44.3003 0.5 47.7924 0.5 52.1002C0.5 56.408 3.99223 59.9003 8.30005 59.9003Z' fill='white'/%3E%3C/svg%3E%0A");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    width: 16px;
    height: 32px;
  }

  &__avatar {
    position: relative;
    aspect-ratio: 1 / 1;
    height: 152%;
    border: 5px solid $color-red;
    border-radius: 50%;
    bottom: 74%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  &__layer {
    position: absolute;
    width: 100%;
    height: 42%;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.4);
    color: $color-white;
    display: flex;
    padding-left: 5%;
  }

  &__right {
    padding-left: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__top {
    display: flex;
    align-items: center;
  }

  &__name {
    font-weight: 800;
    font-size: 18px;
    line-height: 100%;
    white-space: nowrap;

  }

  &__verified {
    white-space: nowrap;
    margin-left: 8px;
    padding-left: 20px;
    font-size: 12px;
    background-image: url("data:image/svg+xml,%3Csvg width='38' height='37' viewBox='0 0 38 37' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M37.0988 18.4003L34.5988 21.9003L35.2988 26.2002L31.4988 28.3003L30.2988 32.6002L25.8989 32.8003L22.9988 36.1002L18.9988 34.4003L14.8989 36.1002L11.9988 32.8003L7.59882 32.6002L6.49884 28.3003L2.59882 26.2002L3.39886 21.9003L0.798828 18.4003L3.39886 14.8003L2.59882 10.5002L6.49884 8.40027L7.59882 4.2002L11.9988 3.90027L14.8989 0.700195L18.9988 2.40027L22.9988 0.700195L25.8989 3.90027L30.2988 4.2002L31.4988 8.40027L35.2988 10.5002L34.5988 14.8003L37.0988 18.4003Z' fill='%2327AAE1'/%3E%3Cpath d='M12.5996 20.6002L17.1996 23.8003L24.4996 12.9003' stroke='white' stroke-width='3.7605' stroke-miterlimit='10' stroke-linecap='round'/%3E%3C/svg%3E%0A");
    background-size: contain;
    background-repeat: no-repeat;
  }
}