.tb-create-account-banner {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 600px;
  background: url(../../../assets/images/create-account-banner/banner.jpg) center top 30px no-repeat, $bg-dark;
  background-size: cover;
  padding-top: 30px;

  &__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background: url(../../../assets/images/create-account-banner/create-account-banner-bg.svg) right top;
    background-repeat: no-repeat;
    background-size: auto 100%;
  }

  &__icon {
    background: url(../../../assets/images//create-account-banner/icon.svg) no-repeat center;
    height: 150px;
    width: 150px;
    background-size: contain;
    margin-bottom: 50px;
  }

  &__button {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;

    & button {
      width: 230px;
    }
  }
}