.tb-header {
  background: $bg-dark;
  height: 100px;

  &__container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100px;
  }

  &__brand {
    height: 41px;

    @include breakpoint-less(md) {
        display: none;
    }
  }

  &__brand-mobile {
    height: 41px;
    display: none;

    @include breakpoint-less(md) {
        display: block;
    }
  }

  &__menu {
    display: flex;
    justify-content: space-between;
    min-width: 50%;

    @include breakpoint-less(md) {
        display: none;
    }
  }

  &__navigation {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    gap: 35px;

    &:first-child {
      margin-right: 70px;
    }

    & li {
      padding: 0;
      margin: 0;
    }

    & a {
      font-family: 'Proxima Nova';
      font-weight: 300;
      font-size: 18px;
      line-height: 22px;
      letter-spacing: 0.075em;
      color: $color-white;
      white-space: nowrap;
    }
  }

  &__small-navigation-button {
    position: relative;
  }

  &__small-navigation {
    position: absolute;
    display: none;
    background: $bg-dark;
    padding: 16px;
    top: 20px;
    z-index: 10000;

    &--open {
      display: block;
    }

    & li {
      height: 32px;

      & a {
        line-height: 32px;
        display: flex;
        align-items: center;
      }

      & span {
        margin-left: 8px;
      }
    }
  }

  &__navigation-mobile {
    display: flex;
    flex-direction: column;
    & a {
      gap: 8px;
      color: $color-white;
      line-height: 32px;
      display: flex;
      align-items: center;
    }

    & span {
      margin-left: 8px;
    }
  }

  &__mobile-menu {
    position: absolute;
    right: 0;
    top: 68px;
    padding: 8px;
    border: 1px solid $border-grey-lighter;
    background: $bg-dark;
    z-index: 9999;
    display: none;

    & .header__navigation {
        flex-direction: column;
        gap: 0;
    }
  }

  &__mobile-menu-button {
    position: relative;
    width: 32px;
    height: 32px;
    display: none;
    background: url(../../../assets/icons/menu-icon.svg) no-repeat center;
    background-size: contain;
    cursor: pointer;

    @include breakpoint-less(md) {
        display: block;
    }

    &--open {
        .tb-header__mobile-menu {
            display: block;
        }
    }
  }
}
