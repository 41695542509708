.tb-landing-best-babes {
  &__title {
    font-size: 26px;
    line-height: 100%;
    letter-spacing: 0.1em;
    color: $color-red;
    text-align: center;
  }

  &__list {
    padding-top: 44px;
  }
}