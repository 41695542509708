/* colors */
.primary {
    background-color: #ca1444;
}

.danger {
    background-color: #ca1444;
}

.dangerText {
    color: #ca1444;
}

/* colors */
.avatar {
    object-fit: cover;
    object-position: center;
}

.cover-bg .avatar {
    display: flex !important;
}

.credit {
    width: 100%;
    padding: 5px;
    font-size: 14px;
    margin-bottom: 5px;
    border: 1px solid #dbdbdb;
}
.profileTabs{
    flex-wrap: wrap;
}
.credit:focus-visible {
    outline-offset: 0px !important;
    outline: 0px !important;
}

.cardBtn {
    padding: 10px 30px;
    margin: 0 auto;
    display: block;
    margin-top: 20px;
    border: none;
    color: #fff;
    border-radius: 5px;
    cursor: pointer;
}

.crediTCardIner {
    display: flex;
    justify-content: space-between;
}

.creditBtns button {
    padding: 5px 15px;
    margin-right: 5px;
    border: none;
    color: #fff;
    border-radius: 5px;
    cursor: pointer;
}

.header-coupon .crediTCardIner {
    margin-bottom: 10px;
}

.customPostFooter {
    display: flex;
    justify-content: space-between !important;
}

.feed-upload {
    display: flex;
    flex-wrap: wrap;
    
}
.outer-over-flow{
    overflow-x: scroll;
    margin-bottom: 18px;
    cursor: grab;

}
.childWrap.outer-over-flow {
    transform: rotateX(180deg);
  }
  .childWrap.outer-over-flow .flex-upper-overflow-x{
    transform: rotateX(180deg);
  }
  .outer-over-flow:focus{
    cursor: grabbing ;
  }

  .outer-over-flow:active{
    cursor: grabbing ;
  }
  .flex-upper-overflow-x{
    cursor: initial !important;
  }
  .flex-upper-overflow-x:active{
    cursor: initial !important;
  }
  



.upload-wrap {
    margin-right: 20px;
}

.albums-modal .card-heading .button {
    display: flex;
    align-items: center;
    padding: 5px 20px !important;
    border-radius: 5px;
}

.button.is-solid.grey-button {
    padding: 10px;
}

.checkout-container {
    margin-top: 35px;
}

.profile-subheader {
    justify-content: center !important;
    margin-top: 45px;
}

.customSelect {
    padding: 10px;
    border: 1px solid silver;
    border-radius: 25px;
}

.ProfilePreview {
    position: relative;
}

.ProfilePreview>img {
    width: 125px;
    height: 125px;
    border-radius: 50%;
    margin: 0 auto;
    display: block;
}

.ProfilePreview>svg {
    position: absolute;
    top: 0px;
    right: 0px;
    cursor: pointer;
}

.navbar-item.is-account img {
    object-fit: cover;
}

.tip-modal {
    margin-top: 70px;
}

.profile-uploader-box {
    width: 100%;
    height: 300px;
    padding: 40px;
    border: 3px dashed #e8e8e8;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

.profile-uploader-box .inner-content svg {
    display: block;
    max-height: 110px;
    margin: 0 auto;
    opacity: 0.5;
    filter: grayscale(1);
    transition: all 0.3s;
    width: 100%;
}

.profile-uploader-box .inner-content span {
    display: block;
    text-align: center;
    color: #cecece;
    padding: 10px 0;
}

#upload-profile-picture {
    display: none;
}

.profile-uploader-box:hover>.inner-content svg {
    filter: grayscale(0);
}

.custom-profile-upload {
    display: block;
    margin: 0 auto;
    width: 300px;
}

.custom-profile-upload img {
    width: 300px;
    height: 300px;
    border-radius: 50%;
    object-fit: cover;
}

.login-wrapper .hero.form-hero .logo-wrap {
    transform: rotate(0deg) !important;
    border-radius: 0% !important;
}

.card.is-new-content .hidden-options .target-channels .channel {
    justify-content: space-between;
}

.post-media {
    display: flex !important;
    justify-content: center;
    align-items: center;
}

.card.is-post .comments-wrap .card-footer .post-comment .actions .toolbar {
    margin-left: auto;
}

.controls-actions-custom {
    display: flex;
    align-items: center;
    gap:15px;
}

.controls-d-block {
    display: block !important;
}

.card.is-post .content-wrap .post-actions {
    display: block !important;
}

.likedPost {
    fill: red;
    stroke: red;
}

.posts-feed-wrapper .post-feed-item {
    border: 1px solid #e8e8e8;
    padding: 10px;
}

.posts-feed-wrapper .post-feed-item .card-content .media .media-left .post-avatar img {
    display: block !important;
}

.sidebar-v1 .top-section .user-block .avatar {
    display: block !important;
}

.chat-input {
    width: 99%;
    height: 40px;
    border: 1px solid;
    margin: 0 auto;
    display: block;
}

.chat-wrapper .chat-inner .chat-body {
    bottom: 42px;
}

.chat-wrapper .chat-inner .chat-body .chat-body-inner .chat-message.is-received+.is-received img {
    visibility: unset !important;
}

.chat-wrapper .chat-inner .chat-body .chat-body-inner .chat-message.is-sent+.is-sent img {
    visibility: unset !important;
}

#agora_local {
    height: 100%;
}

#agora_local video {
    position: relative !important;
}

.videos-modal .card-body .inner .right-section .comment-controls {
    bottom: -50px !important;
}

.like-wrapper {
    display: flex;
    align-items: center;
}

.search-select {
    width: 100%;
    padding: 7px;
    border-radius: 4px;
    border: 1px solid silver;
}

.search-select:focus {
    box-shadow: -1px 3px 10px 0 rgb(0 0 0 / 6%) !important;
}

.search-select:focus-visible {
    outline: none !important;
}

.friend-card .img-container .avatar {
    display: block !important;
    height: 100%;
    border-radius: 70px;
}

.plus-avatar {
    position: absolute;
    bottom: 0px;
    color: #ca1444;
    right: 4px;
    z-index: 1;
    width: 30px;
    cursor: pointer;
    height: 30px;
    background: #fff;
    border-radius: 50%;
}

.is-dark .card-heading {
    color: #fff;
}

.story-avatar .avatar {
    display: block !important;
    object-fit: contain;
}

#share-modal .modal-content {
    width: 61%;
}

#share-modal .modal-content .card {
    padding: 50px;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.user {
    background-color: rgb(202, 20, 68);
    border-radius: 20px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    height: 140px;
    align-items: center;
    text-align: center;
}

.user:hover {
    background: linear-gradient(335deg, rgba(202, 20, 68, 1) 0%, rgba(107, 9, 34, 1) 100%) !important;
    transition: all;
}

.user h1 {
    font-size: 30px;
    color: #fff;
}

.creator h1 {
    font-size: 30px;
    color: #fff;
}

.creator {
    background-color: rgb(202, 20, 68);
    border-radius: 20px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    height: 140px;
    align-items: center;
    text-align: center;
}

.creator:hover {
    background: linear-gradient(335deg, rgba(202, 20, 68, 1) 0%, rgba(107, 9, 34, 1) 100%) !important;
    transition: all;
}

.getStared {
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.getStared h1 {
    font-size: 30px;
}

.creater {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
}

.creater h2 {
    margin-top: 10px;
}

.skip {
    position: absolute;
    right: 26px;
    top: 10px;
    cursor: pointer;
}

.forgotButtons {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column-reverse;
}

.forgotButtons a {
    color: #999;
}

.forgotButtons a:hover {
    color: #ca1444;
}

.forgot-password {
    padding: 0px;
}

.transactions {
    width: 100%;
    max-width: 1040px;
    margin: 0 auto;
    margin-top: 10px;
    border-radius: 10px;
    padding: 20px 20px 20px 20px;
}

.bannerSection {
    width: 100%;
    min-height: 55vh;
}

.bannerInner {
    background-color: #2B004C;
    box-sizing: border-box;
    transition: all 300ms cubic-bezier(0.19, 1, 0.22, 1) 0s;
    padding: 0rem;
    margin: 0rem;
}

.landingContainer {
    box-sizing: border-box;
    margin: 0px auto;
    max-width: 72rem;
    width: 100%;
}

.bannerColumns {
    align-items: flex-start;
    place-content: flex-start;
    box-sizing: border-box;
    display: flex;
    flex-flow: row wrap;
    -webkit-box-pack: start;
    transition: all 300ms cubic-bezier(0.19, 1, 0.22, 1) 0s;
    padding: 0rem;
}

.bannerColumn {
    flex-basis: 50%;
    padding: 11.5rem 20px;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.bannerColumn h1 {
    font-size: 3rem;
    color: #fff;
    text-align: left;
    font-weight: bold;
    line-height: 1.4em;
}

.bannerColumn p {
    font-size: 1.25rem;
    line-height: 1.75;
    color: #fff;
}

.signUpBtn {
    align-items: center;
    backface-visibility: hidden;
    background-color: #ca1444;
    border-radius: 44px;
    border: 1px solid #ca1444;
    box-sizing: border-box;
    cursor: pointer;
    display: inline-flex;
    font-weight: 500;
    height: unset;
    -webkit-box-pack: center;
    justify-content: center;
    padding: 0.78125rem 1.5rem;
    position: relative;
    pointer-events: unset;
    text-align: center;
    text-decoration: none;
    text-transform: none;
    transition: all 300ms cubic-bezier(0.19, 1, 0.22, 1) 0s;
    user-select: none;
    white-space: unset;
    width: unset;
    color: rgb(255, 255, 255);
    font-size: 1rem;
    max-width: 185px;
    margin-top: 20px;
}

.bannerColumn a {
    align-items: center;
    backface-visibility: hidden;
    background-color: #ca1444;
    border-radius: 44px;
    border: 1px solid #ca1444;
    box-sizing: border-box;
    cursor: pointer;
    display: inline-flex;
    font-weight: 500;
    height: unset;
    -webkit-box-pack: center;
    justify-content: center;
    padding: 0.78125rem 1.5rem;
    position: relative;
    pointer-events: unset;
    text-align: center;
    text-decoration: none;
    text-transform: none;
    transition: all 300ms cubic-bezier(0.19, 1, 0.22, 1) 0s;
    user-select: none;
    white-space: unset;
    width: unset;
    color: rgb(255, 255, 255);
    font-size: 1rem;
    max-width: 185px;
    margin-top: 20px;
}

.infoSection {
    margin-top: 100px;
    text-align: center;
    padding-bottom: 4rem;
}

.infoSection h2 {
    font-size: 2.625rem;
    color: #ca1444;
    font-weight: bold;
}

.infoSection p {
    font-size: 1.2rem;
    line-height: 2;
}

.landingColumns {
    align-items: center;
    place-content: flex-start;
    box-sizing: border-box;
    display: flex;
    flex-flow: row wrap;
    -webkit-box-pack: start;
    transition: all 300ms cubic-bezier(0.19, 1, 0.22, 1) 0s;
    padding: 2rem 0;
}

.landingColumn {
    flex-basis: 50%;
    padding-bottom: 6.5rem;
    padding-top: 5.5rem;
    padding: 0 1.5rem;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.landingColumn h2 {
    font-size: 2.125rem;
    color: #ca1444;
    font-weight: bold;
}

.landingColumn p {
    font-size: 1rem;
    line-height: 1.5;
}

.directionColumn {
    flex-direction: column;
    display: flex;
}

.directionColumn a {
    color: #ca1444 !important;
}

.flex {
    display: flex;
}

.between {
    justify-content: space-between;
}

.alignCenter {
    align-items: center;
}

.justifyCenter {
    justify-content: center;
}

.width {
    width: 15%;
}

.width h4 {
    font-weight: 700;
    font-size: 1rem;
    line-height: 1.5;
}

.width span {
    font-size: 0.875rem;
    line-height: 1.5;
}

.rowReverse {
    flex-direction: row-reverse;
}

.landingLinks div {
    padding: 0.5rem 6rem;
}

.landingLinks a {
    border-radius: 4px;
    color: rgb(177, 172, 163);
    cursor: pointer;
    display: inline-block;
    font-weight: 700;
    max-width: 100%;
    overflow: hidden;
    text-decoration: none;
    text-overflow: ellipsis;
    transition: all 300ms cubic-bezier(0.19, 1, 0.22, 1) 0s;
    vertical-align: bottom;
    font-size: 1.625rem;
    line-height: 1.25;
}

.landingLinks a:hover {
    color: rgb(36, 30, 18);
    text-decoration: underline;
}

.d-block {
    display: block;
}

.d-none {
    display: none;
}

.getStarted {
    text-align: center;
}

.getStarted h2 {
    font-size: 2.125rem;
    color: #ca1444;
    font-weight: bold;
}

.getStarted button {
    align-items: center;
    backface-visibility: hidden;
    background-color: #ca1444;
    border-radius: 9999px;
    border: 1px solid #ca1444;
    box-sizing: border-box;
    cursor: pointer;
    display: inline-flex;
    font-weight: 500;
    height: unset;
    -webkit-box-pack: center;
    justify-content: center;
    padding: 0.78125rem 1.5rem;
    position: relative;
    pointer-events: unset;
    text-align: center;
    text-decoration: none;
    text-transform: none;
    transition: all 300ms cubic-bezier(0.19, 1, 0.22, 1) 0s;
    user-select: none;
    white-space: unset;
    width: unset;
    color: rgb(255, 255, 255);
    font-size: 1rem;
    max-width: 134px;
    margin-top: 20px;
}

.landingNav {
    height: 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 40px;
    padding-left: 40px;
    background-color: #2B004C;
}

.landingNavInner a {
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 300;
    font-size: 17px;
    line-height: 17px;
    color: #FFFFFF;
}

.landing-modal .card-footer {
    background-color: #fff !important;
    border: none;
    width: 50%;
    margin: 0 auto !important;
    margin-top: 20px !important;
}

.landing-button {
    width: 15rem;
}

.mediaSupported {
    color: gray;
    font-size: 14px;
}

.more-wrap {
    width: 100%;
}

.more-wrap div {
    width: 100%;
}
.card.is-new-content .compose .feed-upload .upload-wrap img {
    width: 300px;
    height: 100px;
    object-fit: fill;
}

.loadMoreSearch {
    align-items: center;
    backface-visibility: hidden;
    background-color: #ca1444;
    border-radius: 9999px;
    border: 1px solid #ca1444;
    box-sizing: border-box;
    cursor: pointer;
    display: inline-flex;
    font-weight: 500;
    height: unset;
    -webkit-box-pack: center;
    justify-content: center;
    padding: 0.78125rem 1.5rem;
    position: relative;
    pointer-events: unset;
    text-align: center;
    text-decoration: none;
    text-transform: none;
    transition: all 300ms cubic-bezier(0.19, 1, 0.22, 1) 0s;
    user-select: none;
    white-space: unset;
    width: unset;
    color: #fff;
    font-size: 1rem;
    max-width: 134px;
}

.getStarted a {
    align-items: center;
    backface-visibility: hidden;
    background-color: #ca1444;
    border-radius: 9999px;
    border: 1px solid #ca1444;
    box-sizing: border-box;
    cursor: pointer;
    display: inline-flex;
    font-weight: 500;
    height: unset;
    -webkit-box-pack: center;
    justify-content: center;
    padding: 0.78125rem 1.5rem;
    position: relative;
    pointer-events: unset;
    text-align: center;
    text-decoration: none;
    text-transform: none;
    transition: all 300ms cubic-bezier(0.19, 1, 0.22, 1) 0s;
    user-select: none;
    white-space: unset;
    width: unset;
    color: #fff;
    font-size: 1rem;
    max-width: 134px;
}

.profileTabs {
    display: flex;
    margin: 10px 10px;
    gap: 30px;
}

.profileTabs h2 {
    cursor: pointer;
}

.activePostTab {
    color: #ca1444;
    border-bottom: 2px solid #ca1444;
}

.meta-content {
    font-size: 12px !important;
}

.button-wrap {
    display: flex;
    gap: 20px;
}

.lastSection {
    display: flex;
    justify-content: space-between;
}

.lastSection div {
    width: 30%;
}

.lastSection img {
    margin-bottom: 1rem;
}

.lastSection {
    font-weight: 700;
    font-size: 1.3125rem;
    line-height: 1.25;
    margin-bottom: 1rem;
}

.lastSection p {
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5;
}

.search {
    text-align: center;
}

.search h2 {
    font-size: 2.625rem;
    margin-bottom: 2rem;
}

.search div {
    display: flex;
    justify-content: space-between;
}

.search input {
    width: 90%;
    padding: 10px 20px;
    border-radius: 15px;
    border: 1px solid #ca1444;
}

.footer {
    background-color: #2B004C;
    position: relative;
    z-index: 20;
    margin-top: 150px;
    margin-right: -20px;
    margin-left: -20px;
}

.footerColumns div:nth-child(1) {
    width: 40%;
    color: #fff;
}

.footerColumns div {
    width: 60%;
    color: #fff;
}

.footerColumns {
    display: flex;
}

.footer .landingContainer div label {
    margin-bottom: 2rem;
    display: block;
    font-weight: 700;
    font-size: 0.9rem;
    line-height: 1.5;
}

.footer .landingContainer div li {
    font-size: 0.875rem;
    line-height: 2;
}

.landingContainer h3 {
    color: #fff;
    font-size: 0.875rem;
}

.landingContainer a {
    color: #fff;
    font-weight: bold;
}

.backgroundVideo {
    width: 100%;
    height: 550px;
    overflow: hidden;
    position: relative;
    background-image: url("../src/images/landing/7-min.png");
    background-repeat: no-repeat;
    background-size: cover;
}
.upload-wrap.borderWrap.upload-w-full {
    width: 100% !important;
}

.backgroundVideo video {
    position: absolute;
}

.backgrounOverlay {
    width: 100%;
    height: 550px;
    background: rgb(0 0 0 / 50%);
    position: absolute;
    z-index: 1;
}

.backgroundVideo .BackgroundText {
    width: 100%;
    height: 550px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    max-width: 72rem;
    margin: 0 auto;
    z-index: 2;
    padding: 28px;
}

.BackgroundText h2 {
    font-size: 2.125rem;
    color: #ca1444;
    font-weight: bold;
}

.BackgroundText p {
    font-size: 1rem;
    line-height: 40px;
    color: #fff;
}

.BackgroundText a {
    align-items: center;
    backface-visibility: hidden;
    background-color: #ca1444;
    border-radius: 9999px;
    border: 1px solid #ca1444;
    box-sizing: border-box;
    cursor: pointer;
    display: inline-flex;
    font-weight: 500;
    height: unset;
    -webkit-box-pack: center;
    justify-content: center;
    padding: 0.78125rem 1.5rem;
    position: relative;
    pointer-events: unset;
    text-align: center;
    text-decoration: none;
    text-transform: none;
    transition: all 300ms cubic-bezier(0.19, 1, 0.22, 1) 0s;
    user-select: none;
    white-space: unset;
    width: unset;
    color: rgb(255, 255, 255);
    font-size: 1rem;
    max-width: 134px;
    margin-top: 20px;
}

.backgroundVideos {
    width: 100%;
    display: flex;
}

.backgroundVideos div {
    width: 50%;
    cursor: pointer;
}

.marginTop {
    margin-top: -100px;
    position: relative;
    z-index: 1;
}

.marginBottom {
    margin-bottom: 100px;
}

#complain {
    height: 90px;
    resize: initial;
}

#agora_remote video {
    position: relative !important;
}

.postType {
    background: #ca1444;
    padding: 5px 20px;
    border-radius: 28px;
    text-align: center;
    color: #fff;
}

.custom-profile-upload100 {
    width: 100%;
}

.custom-profile-upload100 img {
    width: 100%;
    object-fit: cover;
}

.image {
    object-fit: contain;
}

.avatar-container {
    object-fit: contain;
}

.chat-message {
    object-fit: contain;
}

.suggestionHeader {
    display: flex;
    align-items: center;
    gap: 20px;
    padding: 20px 20px;
    border-bottom: 1px solid silver;
    margin-bottom: 25px;
    flex-wrap: wrap;
}

.suggestionHeader div {
    padding: 10px 40px;
    border-radius: 20px;
    color: #fff;
    cursor: pointer;
    user-select: none;
}

.suggestionBody b {
    color: #fff;
}

.SuggestionMain {
    display: flex;
    align-items: center;
    height: 100%;
    padding: 30px;
    flex-direction: column;
    justify-content: center;
    background-color: #ca1444;
    margin-left: 20px;
}

.SuggestionMain .btns {
    display: flex;
    width: 100%;
    justify-content: space-around;
    margin-top: 60px;
}

.SuggestionMain button {
    padding: 10px 15px;
    border: 1px solid #fff;
    border-radius: 20px;
    background: #fff;
    cursor: pointer;
}

.suggestion-modal .modal-content {
    width: 80% !important;
}

.suggestion-modal .modal-content img {
    width: 300px;
    height: 300px;
    object-fit: contain;
    border-radius: 50%;
}

.suggestion-modal .modal-content .card {
    text-align: center;
    padding: 20px;
}

.customBtn {
    background-color: #ca1444;
    border: 1px solid #ca1444;
    color: #fff;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 20px;
}

.suggestionBody {
    height: 80vh;
    overflow: auto;
}

.post-text {
    white-space: break-spaces;
}

.consent {
    padding: 50px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 70%;
    align-items: center;
    margin: 0px auto;
}

.consent h1 {
    text-align: center;
}

.consent ul {
    list-style: disc;
    padding-left: 20px;
}

.bannerMain img {
    /* display: none; */
}

.customGrid {
    column-gap: 10px;
    column-count: 4;
}

.gridColumn {
    display: inline-block;
    width: 100%;
}

@media screen and (max-width: 900px) and (min-width: 319px) {
    .customGrid {
        column-gap: 10px;
        column-count: 1;
    }

    .gridColumn {
        display: inline-block;
        width: 100%;
    }

    .mobileNone {
        display: none;
    }

    .bannerMain video {
        display: none;
    }

    .bannerMain img {
        display: block;
    }

    .bannerColumn h1 {
        font-size: 25px;
    }

    .BackgroundText h2 {
        font-size: 18px;
    }

    .BackgroundText a {
        font-size: 12px;
    }

    #share-modal .modal-content {
        width: 90%;
    }

    #share-modal .modal-content .card {
        padding: 10px;
    }

    #share-modal .modal-content .card .is-6,
    #share-modal .modal-content .card .is-3 {
        text-align: center;
        margin: 20px;
    }

    .landing-modal .card-footer {
        width: 100%;
    }

    .footerColumns {
        flex-direction: column;
    }

    .footerColumns div {
        width: 100%;
    }

    .marginBottom {
        margin-bottom: 40px;
    }

    .marginTop {
        margin-top: 0;
    }

    .landingNav a {
        font-size: 10px;
        max-width: 80px;
        padding: 5px 8px;
        margin-right: 6px !important;
    }

    .landingNav img {
        width: 110px;
    }

    .backgroundVideo video {
        height: 100%;
        object-fit: cover;
    }

    .backgroundVideo .BackgroundText {
        padding: 0 50px;
    }

    .backgroundVideo p {
        line-height: 25px;
        font-size: 14px;
    }

    .backgroundVideos {
        flex-direction: column;
    }

    .backgroundVideos div {
        width: 100%;
        height: 300px;
    }

    .backgroundVideos video {
        height: 100%;
        width: 100%;
    }

    .infoSection {
        padding-bottom: 0;
    }

    .message-text {
        word-break: break-all;
    }

    .stories-list .container-inner .story {
        margin: 8px 50px;
    }

    .sidebar-v1 .bottom-section {
        height: 100%;
        width: 100%;
        padding: 100px 0 0 0;
    }

    .videos-modal .card-body .inner {
        flex-direction: column;
    }

    .mobile-col {
        margin-top: 0px;
        margin-right: 0px;
        margin-left: 0px;
    }

    .mobile-sidebar-trigger {
        display: none !important;
    }

    .friend-card {
        width: 100% !important;
    }

    .login-container {
        height: 700px;
    }

    .videos-modal .card-body .inner .right-section {
        width: 100%;
    }

    .videos-modal .card-body .inner .right-section {
        width: 100%;
    }

    .videos-modal .card-body .inner .left-section {
        width: 100%;
    }

    .user {
        display: flex;
        align-items: center;
        padding: 20px;
        justify-content: center;
    }

    .user h1 {
        font-size: 25px;
        text-align: center;
    }

    .creator {
        display: flex;
        align-items: center;
        padding: 20px 0px;
        justify-content: center;
    }

    .creator h1 {
        font-size: 25px;
        text-align: center;
    }

    .bannerColumn {
        flex-basis: 90%;
        padding-bottom: 1.5rem;
        padding-top: 1.5rem;
        display: flex;
        justify-content: center;
        flex-direction: column;
    }

    .landingContainer {
        width: 90%;
    }

    .landingColumn {
        padding-bottom: 20px;
        flex-basis: 100%;
        text-align: center;
    }
}

/* width */
.sidebar-v1::-webkit-scrollbar {
    width: 4px;
    border-radius: 0px;
}

/* Track */
.sidebar-v1::-webkit-scrollbar-track {
    background: transparent;
}

/* Handle */
.sidebar-v1::-webkit-scrollbar-thumb {
    background: #c91444 ;
}

/* Handle on hover */
.sidebar-v1::-webkit-scrollbar-thumb:hover {
    background: #c91444;
}


.outer-over-flow::-webkit-scrollbar {
    width: 2px;
    height: 6px;
  }
  
  /* Track */
  .outer-over-flow::-webkit-scrollbar-track {
    border-radius: 0px;
  }
   
  /* Handle */
  .outer-over-flow::-webkit-scrollbar-thumb {
    background: #c91444; 
    border-radius: 0px;
  }
  
  /* Handle on hover */
  .outer-over-flow::-webkit-scrollbar-thumb:hover {
    background: #c91444; 
  }

.modal-content .card {
    overflow: hidden;
}

.text-modal {
    padding: 20px 30px 20px 20px;
}

.btn-red {
    padding: 20px 20px 20px 20px;
    margin-top: auto;
}

.btn-red h1 {
    padding: 20px 40px;
}
.card-masonary .card-content {
    display: flex;
    align-items: self-start;
    padding: 20px 0px 20px;
    min-height: 135px;
}
.card-masonary .card-content .media-left {
    margin-right: 6px;
}
.card-masonary .card-content img.avatar {
    width: 44px;
}
.card-masonary .card-content .media .media-content.is-smaller span:first-child {
    font-size: .9rem;
    overflow-wrap: anywhere;
    font-weight: 400;
}

.card-masonary .card-content .media .media-content span:first-child {
    font-weight: 500;
    color: #393a4f;
}
.card-masonary .card-content .media .media-content span {
    display: block;
    font-family: "Roboto", sans-serif;
    white-space: break-spaces;
}
.card-masonary .card-content .media .media-content.is-smaller span:nth-child(2) {
    font-size: 0.75rem;
    text-align: right;
}

.card-masonary .card-content .media .media-content span:nth-child(2) {
    font-size: 0.85rem;
    color: #a2a5b9;
}
.customGrid.masonaryTwoColum {
    column-count: 2;
}
.card-content.disable-flex {
    display: block;
}
.chlid-div a {
    margin-left: 6px;
}
.social-count {
    display: flex;
    align-items: center;
}
.card-footer.customPostFooter {
    align-items: end;
}
.chlid-div .fab-wrapper .small-fab, .chlid-div .like-wrapper .like-button{
    width: 24px;
    height: 24px;

}
.card-masonary  .card-footer.customPostFooter {
    align-items: start;
    padding: 14px 12px 0;
    margin: 0px 0;
}
.card-masonary  .card-footer .likes-count {
    margin-right: 12px;
}
.card-masonary  .card-content.disable-flex .media {
    margin-bottom: 6px;
    padding: 0 12px;
}
.card-masonary .card-footer.customPostFooter {
    display: flex;
    padding: 14px 8px 0px 4px;
}
.card-masonary .card-footer .likers-group {
    display: flex;
    justify-content: flex-start;
    align-items: start;
}
.card-masonary .likers-group img {
    height: 32px;
    width: 32px;
    border-radius: 50%;
    border: 3px solid #fff;
    object-fit: cover;
}
.card-masonary .card-footer .likers-group img:not(:first-child){
    margin-left: -12px;
}
.card-masonary .card-footer .likers-text {
    margin-left: 18px;
    line-height: 1.4;
}
.card-masonary .card-footer .likers-text p {
    font-size: 11px;
    color: #888da8;
    line-height: 14px;
    margin-right: 8px;
    margin-bottom: 4px;
}
.card-masonary .card-footer .likes-count span{
    font-size:10px;
}

.controls-actions-custom .reply a {
    font-size: 0.75rem !important;
    color: #888da8 !important;
}

span.time {
    display: block;
    font-size: 0.7rem;
    color: #888da8;
    margin-bottom: 10px;
}   

.comments-heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
}

.comments-heading h4 {
    font-size: 1rem;
    font-weight: 500;
    color: #757a91;
}

.media-left img {
    height: 36px;
    width: 36px;
    min-width: 36px;
    border-radius: 50%;
}

.media-content a {
    font-size: 0.85rem;
    font-weight: 500 !important;
    display: block;
    color: #393a4f;
    line-height: 1.2;
}   

.media-content p {
    font-size: 0.9rem !important;
    color: #757a91;
}

.media {
    width: 100%;
}

.close-comments svg {
    height: 18px;
    width: 18px;
    stroke: #757a91;
}

.comments-placeholder {
    text-align: center;
}

.monthInput input{
    display: block;
    top: 20px;
    right: 20px;
    padding: 6px 10px;
    border-radius: 6px;
    border: 1px solid #e0e0e0;
    margin-bottom: 15px;
    cursor: pointer;
    margin-left: auto;
    text-align: left;
    width: 120px;
    background-color: transparent;
}

.monthInput .react-datepicker-popper {
	right: 40px !important;
	left: auto !important;
}

.react-datepicker__month-text--keyboard-selected{
    background-color: #ca1344 !important;
}

.monthInputInner{
    position: relative;
}

.monthInputInner .calendarIcon {
	position: absolute;
	right: 7px;
	top: 7px;
	z-index: 0;
	width: 14px;
	height: 14px;
}

.tooltip {
    position: relative;
    display: inline-block;
    border-bottom: 1px dotted black;
}
  
.tooltip .tooltip-text {
    visibility: hidden;
    width: 120px;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    opacity: 0;
    transition: opacity 3s;
}

.tooltip:hover .tooltip-text {
    visibility: visible;
    opacity: 0.6;
}
/* Top Position the tooltip */
.tooltip-top{
    position: absolute;
    z-index: 1;
    bottom: 122%;
    left: 50%;
    margin-left: -60px;
}

.tooltip-top::after{
    content: " ";
    position: absolute;
    top: 100%; /* At the bottom of the tooltip */
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: black transparent transparent transparent;
}

/* Left Position the tooltip */
.tooltip-left{
    position: absolute;
    z-index: 1;
    top: 1px;
    right: 122%;
}
.tooltip-left::after{
    content: " ";
    position: absolute;
    top: 50%;
    left: 100%; /* To the right of the tooltip */
    margin-top: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent transparent black;
}
/* Right Position the tooltip */
.tooltip-right{
    position: absolute;
    z-index: 1;
    top: -0.2px;
    left: 122%;
}
.tooltip-right::after{
    content: " ";
    position: absolute;
    top: 50%;
    right: 100%; /* To the left of the tooltip */
    margin-top: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent black transparent transparent;
}
/* Bottom Position the tooltip */
.tooltip-bottom{
    position: absolute;
    z-index: 1;
    top: 122%;
    left: 50%;
    margin-left: -60px;
}
.tooltip-bottom::after{
    content: " ";
    position: absolute;
    bottom: 100%;  /* At the top of the tooltip */
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent black transparent;
}


.overlayFull {
    background-color: #000000;
    height: 100%;
    width: 100%;
    position: absolute;
    inset: 0px;
    display: flex;
    z-index: 9;
}

svg.overlayIcon {
    stroke: white;
    width: 30px;
    height: 30px;
    right:0px;
    position: absolute;
    margin-top: 20px;
    margin-right: 20px;
    cursor: pointer;
}

.overlayImage{
    padding: 10px;
    width: 100%;
    height: 100%;   
}

.overlayImage img{
    height: 100%;
    object-fit: contain;
}

.mainForm{
    max-width:100%;
}

.headingButton{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.headingButtonInner{
    display: flex;
    gap: 15px;
    align-items: center;
}

.headingButtonInner svg{
    width: 20px;
    height: 20px;
    cursor: pointer;
    stroke: #a2a5b9;
}

.headingButtonInner button:hover {
    background-color: #f4f4f4;
    border-radius: 4px;
    height: 30px;
}

.headingButton button {
    border: none;
    background: transparent;
}

.borderWrap::after {
    content: '';
    border-bottom: 2px solid #c81444;
    height: 2px;
    width: 100%;
    border-bottom-style: dashed;
    position: absolute;
    right: 0px;
    bottom: -7px;
}  
.card.is-new-content .compose .feed-upload .upload-wrap .remove-file {
    top: 0px;
    right: 0px;
    height: 28px;
    width: 28px;
    border-radius: 4px;
    border-bottom-right-radius: 0px;
    border-top-right-radius: 5px;
    border-top-left-radius: 0px;
} 

.borderWrap {
    margin-right: 40px;
    position: relative;
    margin-bottom: 20px !important;
    margin: auto;
}
/* .upload-wrap.borderWrap {
    width: 200px !important;
}    */

.card.is-new-content .compose .feed-upload .upload-wrap .video-thumbnail{
    top: 0;
    right: 0;
    left: 0;
    margin: 0 auto;
    height: 100%;
}
.card.is-new-content .compose .feed-upload .upload-wrap .video-thumbnail  svg{
    background: rgb(255, 255, 254, 0.3);
    border-radius: 18px;
    min-width: 34px;
    min-height: 34px;
    padding: 6px;
}

.compose-optionFilm {
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 148px;
    height: 110px;
    border-radius: 8px;
    padding: 4px 2px 4px 2px;
    background: #f7f7f7;
    font-size: 0.85rem;
    color: #888da8;
    transition: all 0.3s;
}
.flex-upper-overflow-x{
    display: flex;
    padding-top: 10px;
    padding-bottom: 20px;
}

.compose-optionFilm input[type="file"] {
    opacity: 0;
    width: 100%;
    height: 100%;
}

.attachMain {
    position: absolute;
    width: 100%;
    display: block;
    align-items: center;
    gap: 10px;
    text-align: center;
}
.attachMain svg{
    display: block;
    margin: 0 auto;
    margin-bottom: 10px !important; 

}
.attachMain span {
    font-size: 12px;

}

.childWrap{
    border: 1px solid #c91444;
    border-bottom-left-radius: 0px !IMPORTANT;
    border-bottom-right-radius: 0px !IMPORTANT;
    padding-top: 10px;
    padding-left: 5px;
    padding-right: 5px;
    display: flex;
    align-items: center;
    border-radius: 10px;
    gap: 30px;
    flex-wrap: wrap;
}

.childWrap .upload-wrap {
    margin-right: 0px;
}
.childWrap.outer-over-flow .upload-wrap{
    margin: 0 4px;
    padding: 4px 4px 0;
}
.border-wrap-video{
    border-radius: 8px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
}
.childWrap.outer-over-flow .flex-upper-overflow-x {
    padding-bottom: 0px !important;
}
span.check-ico {
    position: absolute;
    bottom: 0;
    background: rgb(0, 0, 0);
    right: -1px;
    width: 24px;
    height: 24px;
    border-radius: 4px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 0px;
    text-align: center;
    cursor: pointer;
}
span.check-ico svg {
    color: rgb(255, 255, 255);
    width: 16px;
}
span.check-ico:hover{
    background: rgb(201 20 68);
}
span.check-ico:hover  svg{
    color: #fff;
}
.thumbnail-selected{
    background: rgb(201 20 68) !important;
}
.thumbnail-selected svg{
    color: #fff !important;
}

.hintUI {
    display: flex;
    align-items: center;
    background-color: #f5f6f7;
    gap: 4px;
    color: grey;
    width: 100%;
    padding: 8px 26px;
    font-size: 15px;
}

.hintUI svg {
    background: rgb(133 133 133);
    border-radius: 18px;
    min-width: 24px;
    min-height: 24px;
    padding: 5px;
    stroke: white;
}

.hintUiCompose{
    padding: 16px 16px 0px 16px !important;
}

.hintUI  .filmSvg{
    display: flex;
}

.hintUI .alertIcon svg {
    background-color: white;
    stroke: #999;
    stroke-width: 2;
    padding: 0px;
}

.rightCrossMain {
    min-width: 70px;
    min-height: 100%;
    background-color: #f5f6f7;
    border-left: 1px solid #858585;
    display: flex;
    justify-content: center;
    align-items: center;
}

.rightCrossMain .crossIcon {
    background: white;
    border-radius: 18px;
    width: 24px;
    height: 24px;
    cursor: pointer;
}

.hint-loader{
    animation-name: ckw;
    animation-iteration-count: infinite;
    animation-duration: 5s;
}
@keyframes ckw {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.accounts-payouts{
    display:grid !important;
    grid-template-columns:65% auto;
}

@media screen and (max-width:1024px){
    .accounts-payouts{
        grid-template-columns:auto;
    }
    .accounts-payouts .illustration p {
        max-width: 100% !important;
        text-align: center;
    }
    .accounts-payouts .illustration svg{
        max-width: 100% !important;
    }
    .accounts-payouts button.button.is-solid.accent-button.form-button{
        margin-left: auto;
        display: block;
        margin-right: auto;
    }
    .payouts-panel{
        padding:30px 0px !important;
    }
    .payouts-panel-heading{
        padding:0px 30px !important;
    }
}

.bannerColumn .join-btn, #share-modal .join-btn {
    border-radius: 0px !important;
    background-color: #D4A349 !important;
    white-space: nowrap;
    border: none !important;
    color: #fff;
    font-weight: bold;
    font-size: 1rem;
    box-sizing: border-box;
    cursor: pointer;
    display: inline-flex;
    font-weight: 500;
    justify-content: center;
    padding: 0.78125rem 1.5rem;
    position: relative;
    transition: all 300ms
}

.bannerColumn .account-btn, #share-modal .account-btn {
    border-radius: 0px !important;
    background-color: #D9004F !important;
    border: none !important;
    white-space: nowrap;
    color: #fff;
    font-weight: bold;
    font-size: 1rem;
    box-sizing: border-box;
    cursor: pointer;
    display: inline-flex;
    font-weight: 500;
    justify-content: center;
    padding: 0.78125rem 1.5rem;
    position: relative;
    transition: all 300ms
}

.bannerColumn > h2{
    font-size: 2.5rem;
    color: #fff;
}

.post-media video, .post-media img {
    max-height: 90vh;
}