.tb-landing-top-banner {
  padding: 65px 0;
  background: url(../../../assets/images/landing/top-banner-bg.svg) right bottom -1px no-repeat;
  background-size: contain;

  &__wrapper {
    display: flex;
    gap: 55px;

    @include breakpoint-less(md) {
      flex-direction: column;
    }
  }

  &__left {
    width: 50%;

    @include breakpoint-less(md) {
      width: 100%;
    }
  }

  &__right {
    width: 50%;
    background: url(../../../assets/images//landing/top-banner.png) no-repeat center;
    background-size: contain;

    @include breakpoint-less(md) {
      display: none;
    }
  }

  &__title {
    font-size: 64px;
    line-height: 105%;
    letter-spacing: -0.015em;
    color: $bg-dark;
  }

  &__subtitle {
    padding-top: 40px;
    font-size: 32px;
    line-height: 110%;
    color: $color-red;
  }

  &__buttons {
    padding-top: 45px;
    display: flex;
    gap: 20px;

    @include breakpoint-less(sm) {
      flex-direction: column;
    }

    & button {
      width: 200px;

      @include breakpoint-less(sm) {
        width: 100%;
      }
    }
  }
}