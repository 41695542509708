.tb-footer {
  font-family: $font-footer;
  font-weight: 300;

  &--top {
    padding: 70px 0 66px;
  }

  &--middle {
    padding: 0;
    
    @include breakpoint-less(lg) {
      padding: 0 20px;
    }
  }

  &--bottom {
    padding: 40px 0 43px;
  }

  &__container {
    display: flex;
    justify-content: space-between;

    &--top {

    }

    &--bottom {
      padding-top: 89px;

      @include breakpoint-less(lg) {
        padding-top: 0;
        margin-top: 32px;
      }
    }

    @include breakpoint-less(lg) {
        flex-direction: column-reverse;
    }
  }

  &__space {
    @include breakpoint-less(lg) {
        margin-top: 32px;
    }
  }

  &__header {
    width: 238px;
    color: $color-warning;
    padding-bottom: 29px;
    border-bottom: 1px solid $color-warning;
    font-size: 23px;
    line-height: 28px;
    letter-spacing: 0.1em;
  }

  &__socials {
    padding-top: 29px;
    display: flex;
    gap: 13px;
  }

  &__social {
    width: 30px;
    height: 30px;
  }

  &__text {
    padding-top: 36px;
    font-weight: 300;
    font-size: 19px;
    line-height: 35px;
    color: $color-white;
  }

  &__links {
    list-style: none;
    display: flex;
    gap: 16px;
    padding: 0;

    & a {
      color: $color-primary;
    }

    @include breakpoint-less(lg) {
        flex-direction: column-reverse;
        margin-bottom: 32px;
        align-items: center;
    }
  }

  &__copyright {
    @include breakpoint-less(lg) {
        text-align: center;
    }
  }
}
