//
// Background colors //
//
.bg-white-content {
	color: $color-primary;
	h1, h2, h3, h4, h5, h6 { color: $color-dark; }
	a {
		color: $color-dark;
		&:hover, &:focus { color: $color-primary; }
	}
}
.bg-dark-content {
	color: $color-white-06;
	h1, h2, h3, h4, h5, h6 { color: $color-white; }
	a {
		color: $color-white-07;
		&:hover, &:focus { color: $color-white; }
	}
}

.bg-transparent { background: transparent !important; }
.bg-white { background: $color-white !important; @extend .bg-white-content; }
.bg-white-09 { background: $color-white-09 !important; @extend .bg-white-content; }
.bg-white-08 { background: $color-white-08 !important; @extend .bg-white-content; }
.bg-white-07 { background: $color-white-07 !important; @extend .bg-white-content; }
.bg-white-06 { background: $color-white-06 !important; @extend .bg-white-content; }
.bg-white-05 { background: $color-white-05 !important; @extend .bg-white-content; }
.bg-white-04 { background: $color-white-04 !important; @extend .bg-white-content; }
.bg-white-03 { background: $color-white-03 !important; @extend .bg-white-content; }
.bg-white-02 { background: $color-white-02 !important; @extend .bg-white-content; }
.bg-white-01 { background: $color-white-01 !important; @extend .bg-white-content; }
.bg-black { background: $color-black !important; @extend .bg-dark-content; }
.bg-black-09 { background: $color-black-09 !important; @extend .bg-dark-content; }
.bg-black-08 { background: $color-black-08 !important; @extend .bg-dark-content; }
.bg-black-07 { background: $color-black-07 !important; @extend .bg-dark-content; }
.bg-black-06 { background: $color-black-06 !important; @extend .bg-dark-content; }
.bg-black-05 { background: $color-black-05 !important; @extend .bg-dark-content; }
.bg-black-04 { background: $color-black-04 !important; @extend .bg-dark-content; }
.bg-black-03 { background: $color-black-03 !important; @extend .bg-dark-content; }
.bg-black-02 { background: $color-black-02 !important; @extend .bg-dark-content; }
.bg-black-01 { background: $color-black-01 !important; @extend .bg-dark-content; }
.bg-dark-lighter { background: $bg-dark-lighter !important; @extend .bg-dark-content; }
.bg-dark-lightest { background: $bg-dark-lightest !important; @extend .bg-dark-content; }
.bg-grey { background: $bg-grey !important; }
.bg-grey-lighter { background: $bg-grey-lighter !important; }
.bg-grey-lightest { background: $bg-grey-lightest !important; }

//
// Gradient Backgrounds //
//
.bg-grey-gradient-top { background-image: linear-gradient(to top, #E6E6E6, #f5f5f5); }
.bg-grey-gradient-right { background-image: linear-gradient(to right, #E6E6E6, #f5f5f5); }
.bg-grey-gradient-bottom { background-image: linear-gradient(to bottom, #E6E6E6, #f5f5f5); }
.bg-grey-gradient-left { background-image: linear-gradient(to left, #E6E6E6, #f5f5f5); }

//
// Bootstrap - Background colors //
//
.bg-secondary { background: $button-grey-darker !important; @extend .bg-white-content; }
.bg-light { background: $button-grey-lighter !important; @extend .bg-white-content; }
.bg-dark { background: $bg-dark !important; @extend .bg-dark-content; }
.bg-dark, .bg-dark-lighter, .bg-dark-lightest, div[class^='bg-black'], div[class*=' bg-black'] {
	.bg-white, div[class^='bg-white-0'], div[class*=' bg-white-0'] {
		@extend .bg-white-content;
	}
}

//
// Font Families //
//
.font-family-primary { font-family: $font-primary }
.font-family-secondary, .font-family-playfair { font-family: $font-playfair }
//
// Font Weights //
//
.font-weight-thin { font-weight: $font-weight-thin; }
.font-weight-medium { font-weight: $font-weight-medium; }
.font-weight-extra-bold { font-weight: $font-weight-extra-bold; }
.font-weight-black { font-weight: $font-weight-black; }

//
// Text Colors //
//
.text-white-09 { color: $color-white-09; }
.text-white-08 { color: $color-white-08; }
.text-white-07 { color: $color-white-07; }
.text-white-06 { color: $color-white-06; }
.text-white-05 { color: $color-white-05; }
.text-white-04 { color: $color-white-04; }
.text-white-03 { color: $color-white-03; }
.text-white-02 { color: $color-white-02; }
.text-white-01 { color: $color-white-01; }
h1,h2,h3,h4,h5,h6 {
	&.text-white-09 { color: $color-white-09; }
	&.text-white-08 { color: $color-white-08; }
	&.text-white-07 { color: $color-white-07; }
	&.text-white-06 { color: $color-white-06; }
	&.text-white-05 { color: $color-white-05; }
	&.text-white-04 { color: $color-white-04; }
	&.text-white-03 { color: $color-white-03; }
	&.text-white-02 { color: $color-white-02; }
	&.text-white-01 { color: $color-white-01; }
}

.text-black { color: $color-black; }
.text-black-09 { color: $color-black-09; }
.text-black-08 { color: $color-black-08; }
.text-black-07 { color: $color-black-07; }
.text-black-06 { color: $color-black-06; }
.text-black-05 { color: $color-black-05; }
.text-black-04 { color: $color-black-04; }
.text-black-03 { color: $color-black-03; }
.text-black-02 { color: $color-black-02; }
.text-black-01 { color: $color-black-01; }
h1,h2,h3,h4,h5,h6 {
	&.text-black { color: $color-black; }
	&.text-black-09 { color: $color-black-09; }
	&.text-black-08 { color: $color-black-08; }
	&.text-black-07 { color: $color-black-07; }
	&.text-black-06 { color: $color-black-06; }
	&.text-black-05 { color: $color-black-05; }
	&.text-black-04 { color: $color-black-04; }
	&.text-black-03 { color: $color-black-03; }
	&.text-black-02 { color: $color-black-02; }
	&.text-black-01 { color: $color-black-01; }
}

.text-dark { color: $color-dark !important; }

//
// Text Decorations //
//
.text-decoration-overline { text-decoration: overline; }
.text-decoration-line-through { text-decoration: line-through; }
.text-decoration-underline { text-decoration: underline; }
.text-decoration-underline-overline { text-decoration: underline overline; }
a, button {
	&.text-decoration-overline {
		&:hover { text-decoration: overline; }
	}
	&.text-decoration-line-through {
		&:hover { text-decoration: line-through; }
	}
	&.text-decoration-underline {
		&:hover { text-decoration: underline; }
	}
	&.text-decoration-underline-overline {
		&:hover { text-decoration: underline overline; }
	}
}

//
// Borders //
//
.border-all,
.border-y,
.border-x,
.border-top,
.border-right,
.border-bottom,
.border-left {
	border-width: 0;
	border-style: solid;
	border-color: $color-black-01 !important;
}
.border-all { border-width: 1px; }
.border-y { border-top-width: 1px; border-bottom-width: 1px; }
.border-x { border-right-width: 1px; border-left-width: 1px; }
.border-top { border-top-width: 1px; }
.border-right { border-right-width: 1px; }
.border-bottom { border-bottom-width: 1px; }
.border-left { border-left-width: 1px; }
.no-border { border: 0 !important; }

.bg-dark, .bg-dark-lighter, .bg-dark-lightest, div[class^='bg-black'], div[class*=' bg-black'] {
	.border-all, .border-y, .border-x, .border-top, .border-right, .border-bottom, .border-left {
		border-color: $color-white-01 !important;
	}
}

//
// Border Styles //
//
.border-style-dotted { border-style: dotted !important; }
.border-style-dashed { border-style: dashed !important; }

//
// Spacing - Margin //
//
.margin-top-10 { margin-top: 10px !important; }
.margin-top-20 { margin-top: 20px !important; }
.margin-top-30 { margin-top: 30px !important; }
.margin-top-40 { margin-top: 40px !important; }
.margin-top-50 { margin-top: 50px !important; }
.margin-top-60 { margin-top: 60px !important; }
.margin-top-70 { margin-top: 70px !important; }
.margin-right-10 { margin-right: 10px !important; }
.margin-right-20 { margin-right: 20px !important; }
.margin-right-30 { margin-right: 30px !important; }
.margin-left-10 { margin-left: 10px !important; }
.margin-left-20 { margin-left: 20px !important; }
.margin-left-30 { margin-left: 30px !important; }
.margin-bottom-10 { margin-bottom: 10px !important; }
.margin-bottom-20 { margin-bottom: 20px !important; }
.margin-bottom-30 { margin-bottom: 30px !important; }
.margin-bottom-40 { margin-bottom: 40px !important; }
.margin-bottom-50 { margin-bottom: 50px !important; }
.margin-bottom-60 { margin-bottom: 60px !important; }
.margin-bottom-70 { margin-bottom: 70px !important; }
.margin-0, .no-margin { margin: 0 !important; }

//
// Spacing - Padding //
//
.padding-10 { padding: 10px !important; }
.padding-20 { padding: 20px !important; }
.padding-30 { padding: 30px !important; }
.padding-40 { padding: 40px !important; }
.padding-50 { padding: 50px !important; }
.padding-60 { padding: 60px !important; }
.padding-70 { padding: 70px !important; }
.padding-0, .no-padding { padding: 0 !important; }
.padding-top-0, .no-padding-top { padding-top: 0 !important; }
.padding-right-0, .no-padding-right { padding-right: 0 !important; }
.padding-bottom-0, .no-padding-bottom { padding-bottom: 0 !important; }
.padding-left-0, .no-padding-left { padding-left: 0 !important; }