.logout-dialog {
  display: none;
  position: fixed;
  width: 100%;
  max-width: 400px;
  top: calc(40vh);
  left: calc(50vw - 150px);
  background: #fff;
  padding: 16px;
  border: 1px solid $color-black-01;
  border-radius: 4px;
  box-shadow: 0 0 30px 0 rgb(0 0 0 / 30%);
  z-index: 999999;

  &--open {
    display: block;
  }

  &__description {
    margin-bottom: 16px;
    font-size: 24px;
    text-align: center;
  }
}