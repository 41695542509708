.tb-signin-layout {
  display: flex;
  min-height: calc(100vh - 200px);

  &__left {
      width: 50%;
      background: url(../../../assets/images/bg-signin.jpg) no-repeat center;
      background-size: cover;

      @include breakpoint-less(lg) {
          display: none;
      }
  }

  &__left-wrapper {
      background: url(../../../assets/images/bg-signin.svg) no-repeat left bottom;
      background-size: contain;
      width: 100%;
      height: 100%;
  }

  &__right {
      width: 50%;
      height: 100vh;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 0 10vh;

      @include breakpoint-less(lg) {
          width: 100%;
      }
  }

  &__form {
        max-width: 626px;
    }

    &__links {
        margin-top: 42px;

        & a {
            display: block;
            font-size: 18px;
            line-height: 34px;
            color: $color-black;
        }
    }

    & h1 {
        font-size: 60px;
        line-height: 80px;
        color: $color-red;
        margin-bottom: 45px;
    }

    &__form-row {
        margin-bottom: 16px;
    }

    &__button {
        width: 240px;
    }
}
