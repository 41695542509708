.tb-free-gallery-banner {
  background: url(../../../assets/images/free-banner/free-bnner-bg.svg) right bottom -20px, $bg-dark;
  background-repeat: no-repeat;
  background-size: contain;
  height: 762px;
  max-height: 100vh;

  &__wrapper {
    background: url(../../../assets/images/free-banner/free-banner.png) right bottom no-repeat;
    background-size: auto 100%;
    height: 100%;
    display: flex;
    align-items: center;


    @include breakpoint-less(md) {
      background: none;
    }
  }

  &__left {
  }

  &__title {
    font-size: 64px;
    line-height: 100%;
    color: $color-warning;
  }

  &__subtitle {
    font-size: 42px;
    line-height: 105%;
    color: #FFFFFF;
  }

  &__gallery {
    padding-top: 72px;
    font-size: 16px;
    line-height: 100%;
    font-weight: 700;
    letter-spacing: 0.1em;
    color: $color-red;
  }

  &__description {
    font-size: 16px;
    line-height: 100%;
    color: $color-white;
    padding-top: 24px;
    max-width: 332px;
  }

  &__input {
    padding-top: 32px;
  }

  &__button {
    padding-top: 32px;
    max-width: 200px;

    @include breakpoint-less(md) {
      max-width: none;
    }
  }
}