.tb-signup-layout {
  padding: 90px 0 70px;
  background: url('../../../assets/images/bg-signup.svg') no-repeat right top;
  background-size: 40vw;

  &__form {
    max-width: 626px;
  }

  &__links {
    margin-top: 80px;

    & a {
      display: block;
      font-size: 18px;
      line-height: 34px;
      color: $color-black;
    }
  }

  & h1 {
    font-size: 60px;
    line-height: 80px;
    color: $color-red;
    margin-bottom: 48px;
  }

  & input {
    font-size: 24px;
    line-height: 28px;
    background: $color-white;
    border: 1px solid $color-black;
  }


  &__form-row {
    margin-bottom: 16px;
  }

  &__button {
    width: 240px;
  }
}
