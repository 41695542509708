.tb-big-select {
  position: relative;
  width: 100%;

  &__input {
    width: 100%;
    font-size: 24px;
    line-height: 28px;
    background: $color-white;
    border: 1px solid $color-black;
    padding: 13px 20px 14px;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    outline: none;

    &:focus {
      border-color: rgba(0,0,0,.2);
    }
  }

  &__description {
    &--error {
      color: $color-red;
    }
  }
}