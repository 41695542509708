@font-face {
  font-family: 'Proxima Nova';
  font-weight: 400;
  src: url('../assets/fonts/ProximaNova-Regular.woff2') format('woff2'), url('../assets/fonts/ProximaNova-Regular.woff') format('woff');
}

@font-face {
  font-family: 'Proxima Nova';
  font-weight: 300;
  src: url('../assets/fonts/ProximaNova-Light.woff2') format('woff2'), url('../assets/fonts/ProximaNova-Light.woff') format('woff');
}

@font-face {
    font-family: 'Proxima Nova';
    font-weight: 700;
    src: url('../assets/fonts/ProximaNova-Bold.woff2') format('woff2'), url('../assets/fonts/ProximaNova-Bold.woff') format('woff');
  }

@font-face {
  font-family: 'Proxima Nova';
  font-weight: 800;
  src: url('../assets/fonts/ProximaNova-Extrabld.woff2') format('woff2'), url('../assets/fonts/ProximaNova-Extrabld.woff') format('woff');
}

@font-face {
  font-family: 'Bozon';
  font-weight: 400;
  src: url('../assets/fonts/Bozon-Regular.woff2') format('woff2'), url('../assets/fonts/Bozon-Regular.woff') format('woff');
}
