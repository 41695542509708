.tb-for-creators {
  text-align: center;
  padding-top: 60px;
  padding-bottom: 50px;

  &__title {
    font-size: 64px;
    line-height: 100%;
    color: $bg-dark;
  }

  &__subtitle {
    margin-top: 16px;
    font-weight: 700;
    font-size: 16px;
    line-height: 100%;
    letter-spacing: 0.1em;
    color: $color-red;
  }

  &__description {
    margin-top: 10px;
  }

  &__button {
    margin-top: 50px;

    & button {
      max-width: 230px;

      @include breakpoint-less(md) {
        max-width: none;
      }
    }
  }
}