.tb-user-profile {
  &__avatar {
    position: relative;
  }

  &__follow {
    position: absolute;
    width: 44px;
    height: 44px;
    border-radius: 50%;
    background: $color-white;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $color-red;
    font-family: 'Heebo';
    font-weight: 300;
    font-size: 35px;
    line-height: 51px;
    letter-spacing: -0.01em;
    cursor: pointer;
    bottom: 0;
    right: 0;
  }
}