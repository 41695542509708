.tb-button {
  &:not(:disabled) {
    cursor: pointer;
  }

  width: 100%;
  height: 66px;
  font-family: Proxima Nova;
  font-weight: 300;
  font-size: 20px;
  line-height: 25px;
  height: 60px;

  &--primary {
    background: $button-primary-lighter;
    border-color: $button-primary-lighter;
    color: $color-white;
    &.disabled {
      background: $button-primary-lighter;
      color: $color-white;
    }
    &:hover,
    &:not(:disabled):not(.disabled).active,
    &:not(:disabled):not(.disabled):active {
      background: $button-primary;
      border-color: $button-primary;
      color: $color-white;
    }
    &:focus, &.focus {
      box-shadow: 0 0 0 .2rem rgba(0, 123, 255, .5);
    }
  }

  &--secondary {
    background: $color-warning;
    border-color: $color-warning;
    color: $bg-dark;

    &:hover, &:active {
      background: $button-grey-darkest;
      border-color: $button-grey-darkest;
    }
  }

  &--dark {
    background: $bg-dark;
    border-color: $bg-dark;
    color: $color-white;

    &:hover, &:active {
      background: $bg-dark-lighter;
      border-color: $bg-dark-lighter;
    }
  }

  &--white {
    background: $color-white;
    border-color: $color-white;
    color: $bg-dark;

    &:hover, &:active {
      background: $button-grey-darkest;
      border-color: $button-grey-darkest;
    }
  }
}