.tb-empty-list {
  background: url(../../assets/images/bg-viewers.svg) right top no-repeat;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  &__wrapper {
    height: 100vh;
    background: url(../../assets/images/baby-1.png) no-repeat right bottom;
    background-size: contain;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}