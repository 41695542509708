//
// Font //
//
$font-primary: 'Heebo', sans-serif;
$font-playfair: 'Playfair Display', serif;
$font-footer: 'Proxima Nova', serif;
$font-secondary: $font-playfair;
$font-weight-black: 900;
$font-weight-extra-bold: 800;
$font-weight-bold: 700;
$font-weight-medium: 500;
$font-weight-regular: 400;
$font-weight-light: 300;
$font-weight-thin: 100;

//
// Colors //
//
$color-white: #fff;
$color-black: #000;
$color-black-09: rgba(0, 0, 0, 0.9);
$color-black-08: rgba(0, 0, 0, 0.8);
$color-black-07: rgba(0, 0, 0, 0.7);
$color-black-06: rgba(0, 0, 0, 0.6);
$color-black-05: rgba(0, 0, 0, 0.5);
$color-black-04: rgba(0, 0, 0, 0.4);
$color-black-03: rgba(0, 0, 0, 0.3);
$color-black-02: rgba(0, 0, 0, 0.2);
$color-black-01: rgba(0, 0, 0, 0.1);
$color-white-09: rgba(255, 255, 255, 0.9);
$color-white-08: rgba(255, 255, 255, 0.8);
$color-white-07: rgba(255, 255, 255, 0.7);
$color-white-06: rgba(255, 255, 255, 0.6);
$color-white-05: rgba(255, 255, 255, 0.5);
$color-white-04: rgba(255, 255, 255, 0.4);
$color-white-03: rgba(255, 255, 255, 0.3);
$color-white-02: rgba(255, 255, 255, 0.2);
$color-white-01: rgba(255, 255, 255, 0.1);
$color-primary: #646464;
$color-primary-lighter: #a0a0a0;
$color-green: #24963e;
$color-green-lighter: #28a745;
$color-red: #D9004F;
$color-red-lighter: #dc3545;
$color-warning: #D4A349;
$color-warning-lighter: #ffc107;
$color-info: #1491a5;
$color-info-lighter: #17a2b8;
$color-dark: #2B004C;
$color-dark-lighter:  #b00153;

//
// Background colors //
//
$bg-dark: #2B004C;
$bg-dark-lighter: $color-dark-lighter;
$bg-dark-lightest: #222222;
$bg-grey: #f5f5f5;
$bg-grey-lighter: #f7f7f7;
$bg-grey-lightest: #f9f9f9;

//
// Border colors //
//
$border-dark: $color-dark;
$border-dark-lighter: $color-dark-lighter;
$border-grey: #e6e6e6;
$border-grey-lighter: #ebebeb;

//
// Button colors //
//
$button-dark: $color-dark-lighter;
$button-dark-lighter: $color-dark;
$button-grey: #e6e6e6;
$button-grey-lighter: #f0f0f0;
$button-grey-darker: #dcdcdc;
$button-grey-darkest: #d2d2d2;
$button-primary: $color-red-lighter;
$button-primary-lighter: $color-red;
$button-secondary: $color-warning-lighter;
$button-secondary-lighter: $color-warning;
